var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.trustDetails ? _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.message == 'saved' ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(_vm._s(_vm.$t("trust.movprop-success")))])]) : _vm._e(), _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-0 mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.prop-info")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Senarai harta-harta yang akan diagihkan",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('ul', {
    staticClass: "nav nav-tabs mt-4"
  }, [_vm.hibahImmoveable && _vm.userRole != 'Agent' ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'trust.part-f.immoveable' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust.part-f.immoveable',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("immov-prop")) + " ")])], 1) : _vm._e(), _vm.hibahMoveable ? _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'trust.part-f.moveable' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust.part-f.moveable',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mov-prop")) + " ")])], 1) : _vm._e()]), _c('router-view')], 1)]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'trust.part-g',
        query: {
          trust_id: this.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])], 1)])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }